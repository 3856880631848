<template>
  <div id="experience-page">
    <ew-page :name="$route.params.name" :logo="images.logo" experience-path="/experience/" />
    <exp-wc
      ref="wc"
      :name="$route.params.name"
      :logo="images.logo"
    ></exp-wc>
    <div :style="{ color: 'white' }"></div>
  </div>
</template>

<script>
import axios from "axios";
import logo from "../assets/img/logo-mongolia-home-2.png";
export default {
  data() {
    return {
      experience: null,
      copyrights: `© ${new Date().getFullYear()} Mongolia.Travel. All rights reserved.`,
      termsLink: "/terms",
      privacyLink: "/privacy",
      images: {
        logo,
      },
    };
  },
  mounted() {
    if (!this.$route.params.name) {
      this.$router.push("/");
    }
  },
};
</script>
